import React from "react";
import PropTypes from "prop-types";

import { CSVLink } from "react-csv";
import Moment from "react-moment";
import ReactTable from "react-table";
import { SingleSelect } from "react-select-material-ui";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CardText from "components/Card/CardText.jsx";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import RefreshSpinner from "components/Spinners/RefreshSpinner.jsx";
import Slide from "@material-ui/core/Slide";
import TimeAgo from "react-timeago";
import Tooltip from "@material-ui/core/Tooltip";

import {
  aggregationMethods,
  statusTitlesShort,
  statusTitlesShortPlus,
  statusToLongMap,
  statusToShortMap
} from "variables/jnj.jsx";

import api from "state/api";
import combineStyles from "assets/jss/material-dashboard-pro-react/combineStyles.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import tableStyle from "assets/jss/material-dashboard-pro-react/tableStyle.jsx";
import { withStyles } from "@material-ui/core/styles";
import withGracefulUnmount from "react-graceful-unmount";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class OrderTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkActive: 0,
      confirmingBulkEdit: false,
      editingItem: null,
      filteredData: [],
      filterGroup: "None",
      filterStatus: "All",
      jnjLastCursor: "",
      lfLastCursor: "",
      jnjLastUpdated: "2000-01-01T12:00:00Z",
      lfLastUpdated: "2000-01-01T12:00:00Z",
      loading: true,
      newOrgSelected: null,
      newShippingSelected: null,
      newSkuSelected: null,
      newStatusSelected: null,
      orders: [],
      ordersUpdated: 0,
      refreshing: false,
      updating: false,

      sorted: [
        {
          id: "created_at",
          desc: true
        }
      ],
      page: 0,
      pageSize: 20,
      expanded: {},
      resized: [],
      filtered: []
    };

    this.collections = [];
    this.dataTable = React.createRef();
    this.delayTimer = null;
    this.editMode = "";
    this.lineItemCount = 0;
    this.newEmail = null;
    this.newName = null;
    this.selectedItem = {};
    this.status = "";
    this.variants = {};
    this.fetchData = this.fetchData.bind(this);
    this.username = "";
    this.userPermissions = [];
  }

  componentWillUnmount() {
    let state = this.state;
    delete state.orders;
    delete state.filteredData;
    state.sorted = this.dataTable.current.state.sorted;
    state.page = this.dataTable.current.state.page;
    state.pageSize = this.dataTable.current.state.pageSize;
    state.expanded = this.dataTable.current.state.expanded;
    state.resized = this.dataTable.current.state.resized;
    state.filtered = this.dataTable.current.state.filtered;
    localStorage.setItem("OrderTables", JSON.stringify(state));
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem("OrderTables"));
    if (rehydrate) {
      rehydrate.newShippingSelected = null;
      rehydrate.newSkuSelected = null;
      rehydrate.newStatusSelected = null;
      rehydrate.newOrgSelected = null;
      rehydrate.editingItem = null;
      rehydrate.refreshing = false;
      rehydrate.ordersUpdated = 0;
      rehydrate.updating = false;
      this.status = rehydrate.filterStatus;
      if (this.status === "All") {
        this.status = "";
      }
      this.setState(rehydrate);
    }
  }

  componentDidMount() {
    this.updateSearch();

    fetch("/api/user/roles")
      .then(response => response.text())
      .then(data => (this.userPermissions = data));

    fetch("/api/user/username")
      .then(response => response.text())
      .then(data => (this.username = data));

    fetch("/api/meta/jnj_lastOrderRefresh")
      .then(response => api.authCheck(response))
      .then(data => this.setState({ jnjLastUpdated: data["value"] }));
    
    fetch("/api/meta/lf_lastOrderRefresh")
      .then(response => api.authCheck(response))
      .then(data => this.setState({ lfLastUpdated: data["value"] }));

    fetch("/api/meta/jnj_lastOrderCursor")
      .then(response => api.authCheck(response))
      .then(data => this.setState({ jnjLastCursor: data["value"] }));

    fetch("/api/meta/lf_lastOrderCursor")
      .then(response => api.authCheck(response))
      .then(data => this.setState({ lfLastCursor: data["value"] }));

    fetch("/api/collections/basic")
      .then(response => api.authCheck(response))
      .then(data => (this.collections = data["data"]));
  }

  continueRefresh(body, id) {
    var updateCount = this.state.ordersUpdated;
    console.log({body});
    
    const params = Object.keys(body)
      .map(key => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(body[key]);
      })
      .join("&");
    fetch(`/api/orders/${id}/refresh`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      },
      body: params
    })
      .then(response => api.authCheck(response))
      .then(data => {
        if (data["status"]) {
          this.setState({ ordersUpdated: updateCount + data["changeCount"] });
          if (data["hasNextPage"]) {
            let cursor = {};
            cursor[`${id}LastCursor`] = data["cursor"];
            this.setState(cursor);
            if (data["throttleRemaining"] > 1100) {
              this.continueRefresh({ cursor: data["cursor"] }, id);
            } else {
              // Assuming a restore rate of 100, wait until we're back at 1000 throttle remaining
              var seconds = (1100 - data["throttleRemaining"]) / 100 + 0.5;
              setTimeout(
                () => this.continueRefresh({ cursor: data["cursor"] }, id),
                seconds * 1000
              );
            }
          } else {
            // Update the last updated metadata
            const dateString = new Date().toJSON();
            const body = { value: dateString };
            const params = Object.keys(body)
              .map(key => {
                return (
                  encodeURIComponent(key) + "=" + encodeURIComponent(body[key])
                );
              })
              .join("&");
            fetch(`/api/meta/${id}_lastOrderRefresh`, {
              method: "PUT",
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded;charset=UTF-8"
              },
              body: params
            });
            let lastUpdated = {};
            lastUpdated[`${id}LastUpdated`] = dateString;
            this.setState(lastUpdated);
            this.setState({ refreshing: false });
          }
        } else {
          setTimeout(
            () => this.continueRefresh({ cursor: data["cursor"] }, id),
            5000
          );
        }
      });
  }

  fetchData(state, instance) {
    if (this.dataTable.current) {
      this.setState({ loading: true });

      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }
      this.delayTimer = setTimeout(() => {
        this.updateSearch();
      }, 300);
    }
  }

  handleCloseEditModal() {
    this.editMode = "";
    this.setState({ editingItem: null });
    this.setState({ newOrgSelected: null });
  }

  handleCloseShippingModal() {
    this.selectedItem = {};
    this.setState({ newShippingSelected: null });
    this.setState({ confirmingBulkEdit: false });
  }

  handleCloseSkuModal() {
    this.selectedItem = {};
    this.setState({ newSkuSelected: null });
  }

  handleCloseStatusModal() {
    this.selectedItem = {};
    this.setState({ newStatusSelected: null });
    this.setState({ confirmingBulkEdit: false });
  }

  handleEditEmail(item) {
    this.editMode = "Email";
    this.setState({ editingItem: item });
  }

  handleEditName(item) {
    this.editMode = "Name";
    this.setState({ editingItem: item });
  }

  handleEditOrg(item) {
    this.editMode = "Org";
    this.setState({ editingItem: item });
  }

  handleGroupFilterChange(group) {
    this.dataTable.current.state.sorted = [];
    this.dataTable.current.state.resized = [];
    this.dataTable.current.state.filtered = [];
    this.setState({ sorted: [] });
    this.setState({ resized: [] });
    this.setState({ filtered: [] });
    this.setState({ filterGroup: group }, () => {
      this.updateSearch();
    });
  }

  handleSaveEdit() {
    if (this.editMode === "Org") {
      let org = this.state.newOrgSelected;
      const body = {
        changes: [
          { id: this.state.editingItem.line_item_id, collection_name: org }
        ]
      };
      fetch("/api/lineItems", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      })
        .then(response => api.authCheck(response))
        .then(data => {
          this.updateSearch();
        });
    } else if (this.editMode === "Email") {
      let email = this.newEmail;
      const body = { email: email };
      fetch(`/api/orders/${this.state.editingItem.order_id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      })
        .then(response => api.authCheck(response))
        .then(data => {
          this.updateSearch();
        });
    } else if (this.editMode === "Name") {
      let name = this.newName;
      const body = { customer: name };
      fetch(`/api/orders/${this.state.editingItem.order_id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      })
        .then(response => api.authCheck(response))
        .then(data => {
          this.updateSearch();
        });
    }

    this.handleCloseEditModal();
  }

  handleStatusFilterChange(status) {
    this.setState({ filterStatus: status });
    if (status === "Missing") {
      this.status = "Missing";
      this.updateSearch();
    } else if (status === "All") {
      this.status = "";
      this.updateSearch();
    } else {
      this.status = statusToLongMap[status];
      this.updateSearch();
    }
  }

  handleRefresh() {
    this.setState({ refreshing: true });
    if (this.state.jnjLastCursor && this.state.jnjLastCursor.length > 0) {
      this.continueRefresh({ cursor: this.state.jnjLastCursor }, "jnj");
      console.log('refresh for jnj cursor')
    } else {
      this.continueRefresh({ lastUpdate: this.state.jnjLastUpdated }, "jnj");
      console.log('refresh for jnj date')
    }
  }

  handleToggleMissing(item) {
    let missing = item.missing === "1" ? "0" : "1";
    let historyType = item.missing === "1" ? "Missing Resolved" : "Missing";
    const body = { changes: [{ id: item.line_item_id, missing: missing }] };
    fetch("/api/lineItems", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => api.authCheck(response))
      .then(data => {
        this.updateSearch();
      });

    this.recordHistory(item.line_item_id, historyType);
  }

  handleBulkSaveShippingChange() {
    if (!this.state.confirmingBulkEdit) {
      this.setState({ confirmingBulkEdit: true });
      return;
    }

    const newShipping = this.state.newShippingSelected;

    this.updateSearch(ids => {
      let data = ids.map(id => ({ id: id, shipping_type: newShipping }));
      const body = { changes: data };
      fetch("/api/lineItems", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      })
        .then(response => api.authCheck(response))
        .then(data => {
          this.updateSearch();
        });
    });

    this.handleCloseShippingModal();
  }

  handleSaveShippingChange() {
    const newShipping = this.state.newShippingSelected;

    var data = [];
    if (this.state.filterGroup === "None") {
      var item = this.selectedItem;
      data = [{ id: item.line_item_id, shipping_type: newShipping }];
    } else {
      let uids = this.selectedItem.line_item_ids.split("|");
      data = uids.map(uid => {
        return { id: uid, shipping_type: newShipping };
      });
    }

    const body = { changes: data };
    fetch("/api/lineItems", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => api.authCheck(response))
      .then(data => {
        this.updateSearch();
      });

    this.handleCloseShippingModal();
  }

  handleSaveSkuChange() {
    const oldSku = this.selectedItem.sku;
    const newSku = this.state.newSkuSelected;
    const variant = this.variants.find(e => e.sku === newSku);
    if (!variant) {
      console.log("No variant store for", oldSku, newSku, this.selectedItem);
      return;
    }

    var data = [];
    if (this.state.filterGroup === "None") {
      var item = this.selectedItem;
      data = [{ id: item.line_item_id, variant_id: variant.id }];
    } else {
      let uids = this.selectedItem.line_item_ids.split("|");
      data = uids.map(uid => {
        return { id: uid, variant_id: variant.id };
      });
    }

    const body = { changes: data };
    fetch("/api/lineItems", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => api.authCheck(response))
      .then(data => {
        this.updateSearch();
      });

    this.handleCloseSkuModal();
  }

  handleBulkSaveStatusChange() {
    if (!this.state.confirmingBulkEdit) {
      this.setState({ confirmingBulkEdit: true });
      return;
    }

    const newStatus = this.state.newStatusSelected;

    this.updateSearch(ids => {
      let data = ids.map(id => ({ id: id, app_fulfillment_status: newStatus }));
      const body = { changes: data };
      fetch("/api/lineItems", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      })
        .then(response => api.authCheck(response))
        .then(data => {
          this.updateSearch();
        });
    });

    this.handleCloseStatusModal();
  }

  handleOrgChange = (value: string) => {
    this.setState({ newOrgSelected: value });
  };

  handleSaveStatusChange() {
    if (this.state.confirmingBulkEdit) {
      this.setState({ confirmingBulkEdit: false });
      return;
    }

    const newStatus = this.state.newStatusSelected;
    var data = [];

    if (this.state.filterGroup === "None") {
      var item = this.selectedItem;
      data = [{ id: item.line_item_id, app_fulfillment_status: newStatus }];
    } else {
      let uids = this.selectedItem.line_item_ids.split("|");
      data = uids.map(uid => {
        return { id: uid, app_fulfillment_status: newStatus };
      });
    }

    const body = { changes: data };
    fetch("/api/lineItems", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => api.authCheck(response))
      .then(data => {
        this.updateSearch();
      });

    this.handleCloseStatusModal();
  }

  handleShippingChoice(newShipping) {
    if (newShipping !== this.selectedItem.shipping_type) {
      this.setState({ newShippingSelected: newShipping });
    }
  }

  handleSkuChoice(newSkuRaw) {
    let newSku = newSkuRaw.split(" | ")[0];
    if (newSku !== this.selectedItem.sku) {
      this.setState({ newSkuSelected: newSku });
    }
  }

  handleStatusChoice(newStatus) {
    let status = statusToLongMap[newStatus];
    if (newStatus !== this.selectedItem.app_fulfillment_status) {
      this.setState({ newStatusSelected: status });
    }
  }

  handleSkuDropdown(item, originalSku, done) {
    this.selectedItem = item;
    fetch(`/api/skus/siblings/${originalSku}`)
      .then(response => api.authCheck(response))
      .then(data => {
        this.variants = data["data"];
        const skus = this.variants.map(e => e.sku + " | " + e.title);
        done(skus);
      });
  }

  recordHistory(lineItemId, type) {
    const body = {
      line_item_ids: [lineItemId],
      type: type,
      username: this.username
    };
    fetch("/api/histories/bulk", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  renderEditModal() {
    const { classes } = this.props;
    let field = "org name";
    if (this.editMode === "Name") {
      field = "customer name";
    } else if (this.editMode === "Email") {
      field = "customer email";
    }

    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.editingItem != null}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseEditModal()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          {this.editMode === "Org" &&
            this.state.editingItem.print_tags_status === "Complete" && (
              <h5 className={classes.dialogDangerHeader}>
                This item has already been marked Print Tags Complete.
              </h5>
            )}
          <h4 className={classes.dialogHeader}>
            Edit the {field} for this line item.
          </h4>

          {this.editMode === "Org" && (
            <SingleSelect
              value="Org"
              placeholder="Organization name"
              options={this.collections.map(e => e.title)}
              onChange={this.handleOrgChange}
            />
          )}
          {this.editMode === "Email" && (
            <CustomInput
              labelText="Email"
              id="email"
              inputProps={{
                defaultValue: this.state.editingItem.email,
                onChange: e => (this.newEmail = e.target.value)
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          )}
          {this.editMode === "Name" && (
            <CustomInput
              labelText="Name"
              id="name"
              inputProps={{
                defaultValue: this.state.editingItem.customer,
                onChange: e => (this.newName = e.target.value)
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          )}
        </DialogContent>
        {(this.state.newOrgSelected || this.editMode !== "Ord") && (
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => this.handleSaveEdit()} color="info" round>
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }

  renderShippingModal() {
    const { classes } = this.props;
    const isGrouped = this.state.filterGroup !== "None";
    const bulkButton = this.state.confirmingBulkEdit
      ? "Confirm Bulk Edit"
      : "Bulk edit";
    const singleButton = this.state.confirmingBulkEdit
      ? "Cancel"
      : isGrouped
      ? "Edit these items"
      : "Edit this item";

    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.newShippingSelected != null}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseShippingModal()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          {!isGrouped && this.selectedItem.print_tags_status === "Complete" && (
            <h5 className={classes.dialogDangerHeader}>
              This item has already been marked Print Tags Complete.
            </h5>
          )}
          {!isGrouped && (
            <h4 className={classes.dialogHeader}>
              Edit only {this.selectedItem.customer}'s item or all{" "}
              {this.lineItemCount.toLocaleString()} line items in this search?
            </h4>
          )}
          {!this.state.confirmingBulkEdit ? (
            <p>
              The delivery method of <b>{this.selectedItem.shipping_type}</b>{" "}
              will be changed to <b>{this.state.newShippingSelected}</b>
            </p>
          ) : (
            <p className={classes.dialogDangerHeader}>
              The delivery method of {this.lineItemCount.toLocaleString()} line
              items will be changed to <b>{this.state.newShippingSelected}</b>
            </p>
          )}
          {isGrouped && this.selectedItem.line_item_ids && (
            <p>
              This will affect <b>{this.selectedItem.quantity}</b> piece
              {this.selectedItem.quantity > 1 ? "s" : ""} across{" "}
              <b>{this.selectedItem.line_item_count}</b> line item
              {this.selectedItem.line_item_count > 1 ? "s" : ""}.
            </p>
          )}
        </DialogContent>
        <DialogActions
          className={
            classes.modalFooter +
            " " +
            (isGrouped ? classes.modalFooterCenter : "")
          }
        >
          <div className={classes.fullWidth}>
            {this.lineItemCount > 1 &&
              this.userPermissions.includes("admin") &&
              !isGrouped && (
                <Button
                  onClick={() => this.handleBulkSaveShippingChange()}
                  color="danger"
                  round
                >
                  {bulkButton}
                </Button>
              )}
            <Button
              onClick={() => this.handleSaveShippingChange()}
              color="info"
              round
            >
              {singleButton}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  renderSkuModal() {
    const { classes } = this.props;
    const isGrouped = this.state.filterGroup !== "None";

    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.newSkuSelected != null}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseSkuModal()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          {!isGrouped && this.selectedItem.print_tags_status === "Complete" && (
            <h5 className={classes.dialogDangerHeader}>
              This item has already been marked Print Tags Complete.
            </h5>
          )}
          {!isGrouped && this.selectedItem.purchasing_status === "Complete" && (
            <h5 className={classes.dialogDangerHeader}>
              This item has already been marked Purchasing Complete.
            </h5>
          )}
          {!isGrouped && (
            <h4 className={classes.dialogHeader}>
              Edit {this.selectedItem.customer}'s item?
            </h4>
          )}
          {!isGrouped && (
            <p>
              The SKU of <b>{this.selectedItem.sku}</b> will be changed to{" "}
              <b>{this.state.newSkuSelected}</b>
            </p>
          )}
          {isGrouped && this.selectedItem.line_item_ids && (
            <p>
              This will affect <b>{this.selectedItem.quantity}</b> piece
              {this.selectedItem.quantity > 1 ? "s" : ""} across{" "}
              <b>{this.selectedItem.line_item_count}</b> line item
              {this.selectedItem.line_item_count > 1 ? "s" : ""}.
            </p>
          )}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => this.handleSaveSkuChange()} color="info" round>
            Edit this item
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderStatusModal() {
    const { classes } = this.props;
    const isGrouped = this.state.filterGroup !== "None";
    const bulkButton = this.state.confirmingBulkEdit
      ? "Confirm Bulk Edit"
      : "Bulk edit";
    const singleButton = this.state.confirmingBulkEdit
      ? "Cancel"
      : isGrouped
      ? "Edit these items"
      : "Edit this item";

    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.newStatusSelected != null}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseStatusModal()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          {!isGrouped && (
            <h4 className={classes.dialogHeader}>
              Edit only {this.selectedItem.customer}'s item or all{" "}
              {this.lineItemCount.toLocaleString()} line items in this search?
            </h4>
          )}
          {!this.state.confirmingBulkEdit ? (
            <p>
              The fulfillment status of{" "}
              <b>{this.selectedItem.app_fulfillment_status}</b> will be changed
              to <b>{this.state.newStatusSelected}</b>
            </p>
          ) : (
            <p className={classes.dialogDangerHeader}>
              The fulfillment status of {this.lineItemCount.toLocaleString()}{" "}
              line items will be changed to{" "}
              <b>{this.state.newStatusSelected}</b>
            </p>
          )}
          {isGrouped && this.selectedItem.line_item_ids && (
            <p>
              This will affect <b>{this.selectedItem.quantity}</b> piece
              {this.selectedItem.quantity > 1 ? "s" : ""} across{" "}
              <b>{this.selectedItem.line_item_count.toLocaleString()}</b> line
              item{this.selectedItem.line_item_count > 1 ? "s" : ""}.
            </p>
          )}
        </DialogContent>
        <DialogActions
          className={
            classes.modalFooter +
            " " +
            (isGrouped ? classes.modalFooterCenter : "")
          }
        >
          <div className={classes.fullWidth}>
            {this.lineItemCount > 1 &&
              this.userPermissions.includes("admin") &&
              !isGrouped && (
                <Button
                  onClick={() => this.handleBulkSaveStatusChange()}
                  color="danger"
                  round
                >
                  {bulkButton}
                </Button>
              )}
            <Button
              onClick={() => this.handleSaveStatusChange()}
              color="info"
              round
            >
              {singleButton}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }

  updateSearch(lineItemIdCallback = null) {
    this.setState({ loading: true });

    let filters = {};
    if (this.dataTable.current) {
      let state = this.dataTable.current.state;
      filters = {
        page_size: state.pageSize,
        page: state.page,
        sorted: JSON.stringify(state.sorted),
        filtered: JSON.stringify(state.filtered),
        total_page_count: true,
        group_by: this.state.filterGroup
      };
    }

    if (lineItemIdCallback) {
      filters["only_line_items"] = true;
    }

    var body = filters ? filters : {};
    const status = this.status;
    if (status.length > 0) {
      body.status = status;
    }
    const params = Object.keys(body)
      .map(key => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(body[key]);
      })
      .join("&");
    const url = params.length > 0 ? "/api/orders?" : "/api/orders";

    fetch(url + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    })
      .then(response => api.authCheck(response))
      .then(data => {
        if (!lineItemIdCallback) {
          this.setState({ loading: false });
        }

        if (data["status"]) {
          if (lineItemIdCallback) {
            let items = data["data"];
            lineItemIdCallback(items);
          } else {
            let orders = data["data"];
            this.setState({ pages: parseInt(data["pages"]) });
            this.setState({ orders: orders });
            this.lineItemCount = parseInt(data["count"]);
          }
        }
      });
  }

  renderCard() {
    const { classes } = this.props;
    return (
      <Card>
        <CardBody className={classes.cardBodyProducts}>
          {this.renderOrders()}
        </CardBody>
      </Card>
    );
  }

  // TODO: Offload paging and filter logic onto the server
  renderOrders() {
    const { classes } = this.props;
    return (
      <ReactTable
        ref={this.dataTable}
        data={this.state.orders}
        pages={this.state.pages}
        filterable
        manual
        columns={[
          {
            Header: "Status",
            accessor: "app_fulfillment_status",
            sortable: false,
            filterable: false,
            Cell: ({ row }) => (
              <CustomDropdown
                buttonText={statusToShortMap[row.app_fulfillment_status]}
                buttonProps={{
                  style: { marginBottom: "0", width: "120px" },
                  color: "info"
                }}
                dropdownList={statusTitlesShort}
                onPopulate={() => {
                  this.selectedItem = row._original;
                }}
                onClick={e => this.handleStatusChoice(e)}
              />
            ),
            width: 140
          },
          {
            Header: "Delivery",
            accessor: "shipping_type",
            Cell: ({ row }) => (
              <span>
                <CustomDropdown
                  buttonText={row.shipping_type}
                  buttonProps={{
                    style: { marginBottom: "0", width: "140px" },
                    color: "info"
                  }}
                  dropdownList={[
                    "Individual Shipping",
                    "Individual Pickup",
                    "Group Pickup",
                    "Group Delivery"
                  ]}
                  onPopulate={() => {
                    this.selectedItem = row._original;
                  }}
                  onClick={e => this.handleShippingChoice(e)}
                />
              </span>
            ),
            width: 155
          },
          {
            Header: "SKU",
            accessor: "sku",
            Cell: ({ row }) => (
              <span>
                {this.state.filterGroup !== "Product" &&
                this.state.filterGroup !== "Product & Org" ? (
                  <CustomDropdown
                    buttonText={row.sku}
                    buttonProps={{
                      style: { marginBottom: "0", width: "120px" },
                      color: "info"
                    }}
                    dropdownList={[]}
                    onPopulate={(sku, done) =>
                      this.handleSkuDropdown(row._original, sku, done)
                    }
                    onClick={e => this.handleSkuChoice(e)}
                  />
                ) : (
                  <span>{row.sku}</span>
                )}
              </span>
            ),
            width: 140
          },
          {
            Header: "Missing",
            accessor: "missing",
            show: this.state.filterGroup === "None",
            filterable: false,
            className: classes.center,
            Cell: ({ row }) => (
              <Tooltip
                id="tooltip-top-start"
                disableHoverListener={
                  row._original.missing !== "1" || !row._original.missing_by
                }
                title={
                  <p>
                    Marked missing by {row._original.missing_by}{" "}
                    <TimeAgo date={row._original.missing_at} />
                  </p>
                }
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Checkbox
                  checked={row._original.missing === "1"}
                  onClick={() => this.handleToggleMissing(row._original)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              </Tooltip>
            ),
            width: 90
          },
          {
            Header: "Order",
            accessor: "name",
            show: this.state.filterGroup === "None",
            maxWidth: 80
          },
          {
            Header: "Created",
            accessor: "created_at",
            filterable: false,
            show: this.state.filterGroup === "None",
            Cell: ({ row }) => (
              <span>
                {row._original.created_at && (
                  <Moment format="MM/DD/YY h:mm a">
                    {row._original.created_at}
                  </Moment>
                )}
              </span>
            ),
            minWidth: 90
          },
          {
            Header: "ETA",
            accessor: "eta",
            filterable: false,
            Cell: ({ row }) => (
              <span>
                {row._original.eta && (
                  <Moment format="MM/DD/YY">
                    {row._original.eta}
                  </Moment>
                )}
              </span>
            ),
            minWidth: 90
          },
          {
            Header: "Name",
            accessor: "customer",
            show: this.state.filterGroup === "None",
            Cell: ({ row }) => (
              <div>
                {this.state.filterGroup === "None" ? (
                  <span
                    className={classes.editableText}
                    onClick={() => this.handleEditName(row._original)}
                  >
                    {row._original.customer ? row._original.customer : "None"}
                  </span>
                ) : (
                  <span>{row._original.customer}</span>
                )}
              </div>
            )
          },
          {
            Header: "Email",
            accessor: "email",
            show: this.state.filterGroup === "None",
            Cell: ({ row }) => (
              <div>
                {this.state.filterGroup === "None" ? (
                  <span
                    className={classes.editableText}
                    onClick={() => this.handleEditEmail(row._original)}
                  >
                    {row._original.email ? row._original.email : "None"}
                  </span>
                ) : (
                  <span>{row._original.email}</span>
                )}
              </div>
            )
          },
          {
            Header: "Org",
            accessor: "collection_name",
            show:
              this.state.filterGroup === "None" ||
              this.state.filterGroup === "Product & Org",
            Cell: ({ row }) => (
              <div>
                {this.state.filterGroup === "None" ? (
                  <span
                    className={classes.editableText}
                    onClick={() => this.handleEditOrg(row._original)}
                  >
                    {row._original.collection_name}
                  </span>
                ) : (
                  <span>{row._original.collection_name}</span>
                )}
              </div>
            )
          },
          {
            Header: "Orgs",
            accessor: "collections",
            filterable: false,
            show:
              this.state.filterGroup === "Product" ||
              this.state.filterGroup === "SKU"
          },
          {
            Header: "Product",
            accessor: "title"
          },
          {
            Header: "Size",
            headerStyle: {
              paddingRight: "10px",
              textAlign: "right"
            },
            accessor: "size",
            maxWidth: 60,
            className: classes.right,
            show:
              this.state.filterGroup === "None" ||
              this.state.filterGroup === "SKU"
          },
          {
            Header: "Style",
            accessor: "style",
            show:
              this.state.filterGroup === "None" ||
              this.state.filterGroup === "SKU"
          },
          {
            Header: "Color",
            accessor: "color",
            show:
              this.state.filterGroup === "None" ||
              this.state.filterGroup === "SKU"
          },
          {
            Header: "Qty",
            headerStyle: {
              paddingRight: "10px",
              textAlign: "right"
            },
            accessor: "quantity",
            maxWidth: 60,
            filterable: false,
            className: classes.right,
            sortMethod: (a, b, desc) => {
              a = a === 0 ? (desc ? -Infinity : Infinity) : a;
              b = b === 0 ? (desc ? -Infinity : Infinity) : b;
              if (parseInt(a) > parseInt(b)) {
                return 1;
              }
              if (parseInt(a) < parseInt(b)) {
                return -1;
              }
              return 0;
            }
          },
          {
            Header: "Line Items",
            headerStyle: {
              paddingRight: "10px",
              textAlign: "right"
            },
            accessor: "line_item_count",
            filterable: false,
            show: this.state.filterGroup !== "None",
            className: classes.right,
            sortMethod: (a, b, desc) => {
              a = a === 0 ? (desc ? -Infinity : Infinity) : a;
              b = b === 0 ? (desc ? -Infinity : Infinity) : b;
              if (parseInt(a) > parseInt(b)) {
                return 1;
              }
              if (parseInt(a) < parseInt(b)) {
                return -1;
              }
              return 0;
            }
          },
          {
            Header: "Price",
            headerStyle: {
              paddingRight: "10px",
              textAlign: "right"
            },
            accessor: "price",
            filterable: false,
            className: classes.right,
            show:
              this.state.filterGroup === "None" ||
              this.state.filterGroup === "SKU",
            Cell: ({ row }) => `$${row.price}`,
            maxWidth: 80,
            sortMethod: (a, b, desc) => {
              a = a === 0 ? (desc ? -Infinity : Infinity) : a;
              b = b === 0 ? (desc ? -Infinity : Infinity) : b;
              if (parseFloat(a) > parseFloat(b)) {
                return 1;
              }
              if (parseFloat(a) < parseFloat(b)) {
                return -1;
              }
              return 0;
            }
          },
          {
            Header: "Payment",
            headerStyle: {
              paddingRight: "10px",
              textAlign: "right"
            },
            accessor: "payment_type",
            className: classes.right,
            show: this.state.filterGroup === "None"
          }
        ]}
        loading={this.state.loading}
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"
        onFetchData={this.fetchData} // Request new data when things change
        defaultSorted={this.state.sorted}
        defaultPage={this.state.page}
        defaultPageSize={this.state.pageSize}
        defaultExpanded={this.state.expanded}
        defaultResized={this.state.resized}
        defaultFiltered={this.state.filtered}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const headers = [
      "Order",
      "Created",
      "ETA",
      "Name",
      "Email",
      "Org",
      "Product",
      "Size",
      "Style",
      "Color",
      "SKU",
      "Qty",
      "Price",
      "Payment",
      "Shipping",
      "Status"
    ];
    const dataNames = [
      "name",
      "created_at",
      "eta",
      "customer",
      "email",
      "collection_name",
      "title",
      "size",
      "style",
      "color",
      "sku",
      "quantity",
      "price",
      "payment_type",
      "shipping_type",
      "app_fulfillment_status"
    ];
    return (
      <GridContainer justify="center">
        <GridItem xs={0} md={8} />
        <GridItem xs={12} md={4}>
          <RefreshSpinner
            onClick={() => this.handleRefresh()}
            objectName="orders"
            refreshing={this.state.refreshing}
            updateCount={this.state.ordersUpdated}
            updatedDate={this.state.jnjLastUpdated}
          />
        </GridItem>
        <GridItem xs={4} sm={3} lg={2} className={classes.center}>
          <span className={classes.filterHeader}>Filter by</span>
          <CustomDropdown
            buttonText={this.state.filterStatus}
            buttonProps={{
              style: { marginBottom: "0", width: "120px" },
              color: "rose"
            }}
            dropdownList={statusTitlesShortPlus}
            onClick={e => this.handleStatusFilterChange(e)}
          />
        </GridItem>
        <GridItem xs={4} sm={3} lg={2} className={classes.center}>
          <span className={classes.filterHeader}>Group by</span>
          <CustomDropdown
            buttonText={this.state.filterGroup}
            buttonProps={{
              style: { marginBottom: "0", width: "120px" },
              color: "rose"
            }}
            dropdownList={aggregationMethods}
            onClick={e => this.handleGroupFilterChange(e)}
          />
        </GridItem>
        {this.renderShippingModal()}
        {this.renderSkuModal()}
        {this.renderStatusModal()}
        {this.renderEditModal()}
        <GridItem xs={12}>{this.renderCard()}</GridItem>
        <GridItem xs={12}>
          <CardText color="rose" className={classes.dateCardText}>
            <CSVLink
              data={this.state.filteredData}
              asyncOnClick={true}
              filename={"orders.csv"}
              headers={headers}
              onClick={(event, done) => {
                this.setState(
                  {
                    updating: true
                  },
                  () => {
                    var currentRecords = this.dataTable.current.getResolvedState()
                      .sortedData;
                    var data_to_download = [];
                    currentRecords.forEach(function(item, index) {
                      var fi = {};
                      dataNames.forEach(function(name, i) {
                        var header = headers[i];
                        var value = item[name];
                        fi[header] = value ? value.replace('"', "”") : "";
                      });
                      data_to_download.push(fi);
                    });
                    this.setState(
                      {
                        filteredData: data_to_download
                      },
                      () => {
                        done();
                      }
                    );
                  }
                );
              }}
              className={classes.exportButton}
            >
              Export CSV
            </CSVLink>
          </CardText>
        </GridItem>
      </GridContainer>
    );
  }
}

OrderTables.propTypes = {
  classes: PropTypes.object
};

export default withStyles(combineStyles(tableStyle, modalStyle))(
  withGracefulUnmount(OrderTables)
);
