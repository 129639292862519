import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import Datetime from "react-datetime";
import Moment from 'react-moment'
import ReactTable from "react-table";
import moment from "moment-timezone";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Slide from "@material-ui/core/Slide";

import api from "state/api";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import tableStyle from "assets/jss/material-dashboard-pro-react/tableStyle.jsx";
import { withStyles } from '@material-ui/core/styles';
import combineStyles from 'assets/jss/material-dashboard-pro-react/combineStyles.jsx'
import withGracefulUnmount from 'react-graceful-unmount'

import { aggregationMethods, statusTitlesFulfillment } from "variables/jnj.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class FulfillmentTables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bulkActive: 0,
      filteredData: [],
      filters: {},
      filterGroup: "None",
      loading: false,
      modal: false,
      orders: [],
      pending: true,
      ready: false,
      selectedDate: null,
      selectedDateClicked: false,
      status: "Pending",
      updating: false,

      sorted: [{
          id: "name",
          desc: true
      }],
      page: 0,
      pageSize: 20,
      expanded: {},
      resized: [],
      filtered: []
    };

    this.baseOrders = [];
    this.bulkEdit = false;
    this.dataTable = React.createRef();
    this.selectedItem = {};
    this.status = "Pending";
  }

  componentWillUnmount() {
    let state = this.state;
    delete state.orders;
    delete state.filteredData;
    state.sorted = this.dataTable.current.state.sorted;
    state.page = this.dataTable.current.state.page;
    state.pageSize = this.dataTable.current.state.pageSize;
    state.expanded = this.dataTable.current.state.expanded;
    state.resized = this.dataTable.current.state.resized;
    state.filtered = this.dataTable.current.state.filtered;
    localStorage.setItem('FulfillmentTables', JSON.stringify(state))
    localStorage.setItem('FulfillmentTablesLocal', JSON.stringify({
      selectedItem: this.selectedItem
    }))
  }

  componentWillMount() {
    let rehydrate = JSON.parse(localStorage.getItem('FulfillmentTables'));
    if (rehydrate) {
      this.status = rehydrate.status;
      rehydrate.modal = false;
      this.setState(rehydrate)
    }

    let rehydrateLocal = JSON.parse(localStorage.getItem('FulfillmentTablesLocal'));
    if (rehydrateLocal) {
      this.selectedItem = rehydrateLocal.selectedItem
    }
  }

  componentDidMount() {
    this.updateSearch();
  }

  handleClick(item) {
    this.setState({
      updating: true
    }, () => {
      this.selectedItem = item;
      if (item.released_at != null) {
        this.setState({ selectedDate: moment(item.released_at, "YYYY-MM-DD HH:mm:ss Z") });
      }
      this.handleOpenModal();
    })
  }

  handleDateTimeChange(date) {
    this.setState({selectedDate: date});
    this.setState({selectedDateClicked: true});
  }

  handleOpenModal() {
    this.setState({modal: true});
  }

  handleCloseModal() {
    this.setState({modal: false});
    this.setState({selectedDate: null});
    this.setState({selectedDateClicked: false});
  }

  handleSaveReadyModal() {
    this.handleCloseModal();

    var data = [];
    if (this.state.filterGroup === "None") {
      if (this.bulkEdit) {
        data = this.dataTable.current.getResolvedState().sortedData;
        data = data.map( e => {return {id: e._original.line_item_id, app_fulfillment_status: 'On Deck'}});
      } else {
        var e = this.selectedItem;
        data = [{id: e.line_item_id, app_fulfillment_status: 'On Deck'}];
      }
    } else {
      let uids = this.selectedItem.lineItems;
      data = uids.map( uid => {return {id: uid, app_fulfillment_status: 'On Deck'}});
    }

    const body = {changes: data};
    fetch('/api/lineItems', {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    .then(response => api.authCheck(response))
    .then(data => {
      this.updateSearch();
    });
  }

  handleSaveReleaseModal() {
    this.handleCloseModal();

    var data = [];
    let offset =  moment().tz("America/New_York").format('Z');
    var release = this.state.selectedDate.utcOffset(offset).format('YYYY-MM-DD HH:mm:ss');
    if (this.state.filterGroup === "None") {
      if (this.bulkEdit) {
        data = this.dataTable.current.getResolvedState().sortedData;
        data = data.map( e => {return {id: e._original.line_item_id, released_at: release}});
      } else {
        var e = this.selectedItem;
        data = [{id: e.line_item_id, released_at: release}];
      }
    } else {
      let uids = this.selectedItem.lineItems;
      data = uids.map( uid => {return {id: uid, released_at: release}});
    }

    const body = {changes: data};
    fetch('/api/lineItems', {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
    .then(response => api.authCheck(response))
    .then(data => {
      this.updateSearch();
    });
  }

  handleStatusFilterChange(status) {
    this.setState({ status: status });
    this.status = status;
    this.updateSearch();
  }

  handleModalNavigationChange(index) {
    this.bulkEdit = index > 0;
  }

  handleGroupFilterChange(group) {
    if (group === "None") {
      this.setState({ orders: this.baseOrders }, function () {
        this.updateSearch();
      });
    } else if (group === "Product"){
      let groupedOrders = [];
      this.baseOrders.forEach(o => {
        let productSku = o.sku ? o.sku.substring(0, o.sku.lastIndexOf("-")) : "";
        let key = `${o.app_fulfillment_status}|${o.shipping_type}|${o.title}|${productSku}`;
        if (groupedOrders[key]) {
          let order = groupedOrders[key];
          order.quantity += parseInt(o.quantity);
          order.lineItems.push(o.line_item_id);
          if (!order.orgs.includes(o.collection_name)) {
            order.orgs.push(o.collection_name);
          }
          if (!order.print_tags_status === o.print_tags_status) {
            order.print_tags_status = "Mixed";
          }
          if (!order.purchasing_status === o.purchasing_status) {
            order.purchasing_status = "Mixed";
          }
        } else {
          let order = {};
          order.app_fulfillment_status = o.app_fulfillment_status;
          order.title = o.title;
          order.quantity = parseInt(o.quantity);
          order.lineItems = [o.line_item_id];
          order.orgs = [o.collection_name];
          order.shipping_type = o.shipping_type;
          order.print_tags_status = o.print_tags_status;
          order.purchasing_status = o.purchasing_status;
          order.released_at = o.released_at;
          order.sku = productSku;
          groupedOrders[key] = order;
        }
      });

      this.setState({ orders: Object.values(groupedOrders)});
    } else if (group === "Product & Org"){
      let groupedOrders = [];
      this.baseOrders.forEach(o => {
        let productSku = o.sku ? o.sku.substring(0, o.sku.lastIndexOf("-")) : "";
        let key = `${o.app_fulfillment_status}|${o.shipping_type}|${o.title}|${o.collection_name}|${productSku}`;
        if (groupedOrders[key]) {
          let order = groupedOrders[key];
          order.quantity += parseInt(o.quantity);
          order.lineItems.push(o.line_item_id);
          if (!order.print_tags_status === o.print_tags_status) {
            order.print_tags_status = "Mixed";
          }
          if (!order.purchasing_status === o.purchasing_status) {
            order.purchasing_status = "Mixed";
          }
        } else {
          let order = {};
          order.app_fulfillment_status = o.app_fulfillment_status;
          order.title = o.title;
          order.quantity = parseInt(o.quantity);
          order.lineItems = [o.line_item_id];
          order.collection_name = o.collection_name;
          order.shipping_type = o.shipping_type;
          order.print_tags_status = o.print_tags_status;
          order.purchasing_status = o.purchasing_status;
          order.released_at = o.released_at;
          order.sku = productSku;
          groupedOrders[key] = order;
        }
      });

      this.setState({ orders: Object.values(groupedOrders)});
    } else if (group === "SKU") {
      let groupedOrders = [];
      this.baseOrders.forEach(o => {
        let key = `${o.app_fulfillment_status}|${o.shipping_type}|${o.sku}`;
        if (groupedOrders[key]) {
          let order = groupedOrders[key];
          order.quantity += parseInt(o.quantity);
          order.lineItems.push(o.line_item_id);
          if (!order.orgs.includes(o.collection_name)) {
            order.orgs.push(o.collection_name);
          }
          if (!order.print_tags_status === o.print_tags_status) {
            order.print_tags_status = "Mixed";
          }
          if (!order.purchasing_status === o.purchasing_status) {
            order.purchasing_status = "Mixed";
          }
        } else {
          let order = {};
          order.app_fulfillment_status = o.app_fulfillment_status;
          order.title = o.title;
          order.quantity = parseInt(o.quantity);
          order.lineItems = [o.line_item_id];
          order.orgs = [o.collection_name];
          order.shipping_type = o.shipping_type;
          order.price = o.price;
          order.size = o.size;
          order.sku = o.sku;
          order.style = o.style;
          order.color = o.color;
          order.print_tags_status = o.print_tags_status;
          order.purchasing_status = o.purchasing_status;
          order.released_at = o.released_at;
          groupedOrders[key] = order;
        }
      });

      this.setState({ bulkActive: 1});
      this.setState({ orders: Object.values(groupedOrders)});
    }

    this.setState({ filterGroup: group });
  }

  handleGroupFilterChangeByClick(group) {
    this.dataTable.current.state.sorted = [];
    this.dataTable.current.state.resized = [];
    this.dataTable.current.state.filtered = [];
    this.setState({ sorted: [] });
    this.setState({ resized: [] });
    this.setState({ filtered: [] });
    this.handleGroupFilterChange(group);
  }

  updateSearch(filters) {
    this.setState({ loading: true });
    this.setState({ pending: false });
    this.setState({ ready: true });

    var body = filters;
    if(!filters) {
      body = this.state.filters;
    }
    const status = this.status;
    if (status.length > 0) {
      if (status === "On Deck") {
        body.status = "On Deck Not Scheduled";
      } else {
        body.status = status;
      }
    }
    body.purchasing_status = 'Complete';
    body.print_tags_status = 'Complete';
    const params = Object.keys(body).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);}).join('&');
    const url = params.length > 0 ? '/api/orders?' : '/api/orders'

    fetch(url + params, {
      method: 'GET',
      headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
    })
    .then(response => api.authCheck(response))
    .then(data => {
      this.setState({ loading: false });

      if (data['status']) {
        var orders = data['data'];
        this.baseOrders = orders;
        if (this.state.filterGroup !== "None") {
          this.handleGroupFilterChange(this.state.filterGroup);
        } else {
          this.setState({orders: orders});
        }
      }
    });
  }

  renderCard() {
    const { classes } = this.props;
    return (
      <Card>
        <CardBody className={classes.cardBodyProducts}>
          {this.renderOrders()}
        </CardBody>
      </Card>
    );
  }

  renderModal() {
    const isBulkRequired = this.state.filterGroup !== "None";
    const { classes } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={this.state.modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseModal()}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
        {!isBulkRequired &&
          <NavPills
            alignCenter
            color="warning"
            onChange={(e) => this.handleModalNavigationChange(e)}
            tabs={[
              {
                tabButton: "Single",
                tabContent: (
                  <h4 className={classes.dialogHeader}>Edit only {this.selectedItem.customer}'s item.</h4>
                )
              },
              {
                tabButton: "Bulk",
                tabContent: (
                  <h4 className={classes.dialogDangerHeader}>Edit all {this.dataTable.current ? this.dataTable.current.getResolvedState().sortedData.length : 0} items.</h4>
                )
              }
            ]}
          />
        }
        { this.state.status === 'Pending' &&
        <p>
          Moving <b>On Deck</b> indicates that an order is ready to be received by the customer.<br/>
          No automated notifications will be sent out.
        </p>
        }
        { (this.state.status === 'On Deck' || this.state.status === 'Scheduled') &&
        <div>
          <p>
            Schedule a date and time for customers to receive notification that their items are ready.
          </p>
          <Datetime
            className={classes.dateTime}
            defaultValue={moment().startOf('day').hour(12).minute(50)}
            value={this.state.selectedDate}
            inputProps={{ placeholder: "Choose a time", className: classes.dateTimeInput, readOnly: true }}
            onChange={(e) => this.handleDateTimeChange(e)}
            />
        </div>
        }
        {isBulkRequired && this.selectedItem.lineItems &&
          <p>
            This will affect <b>{this.selectedItem.quantity}</b> piece{this.selectedItem.quantity > 1 ? "s" : ""} across <b>{this.selectedItem.lineItems.length}</b> line item{this.selectedItem.lineItems.length > 1 ? "s" : ""}.
          </p>
        }
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter }>
        { this.state.status === 'Pending' &&
          <Button
            onClick={() => this.handleSaveReadyModal()}
            color="info"
            round>
            On Deck
          </Button>
        }
        { (this.state.status === 'On Deck' || this.state.status === 'Scheduled') && this.state.selectedDateClicked &&
          <Button
            onClick={() => this.handleSaveReleaseModal()}
            color="info"
            round>
            Schedule Ready
          </Button>
        }
        </DialogActions>
      </Dialog>
    );
  }

// TODO: Offload paging and filter logic onto the server
  renderOrders() {
    const { classes } = this.props;
    return (
      <ReactTable
        ref={this.dataTable}
        data={this.state.orders}
        filterable
        defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id] : '').toLowerCase().includes(filter.value.toLowerCase())}
        columns={[
        {
          Header: "Action",
          sortable: false,
          filterable: false,
          show: this.state.status === 'Pending',
          Cell: ({ row }) => (
            <Button simple color="white" className={classes.actionButton} onClick={() => this.handleClick(row._original)}>
              Move On Deck
            </Button>
          ),
          width: 140
        },
        {
          Header: "Schedule",
          accessor: "released_at",
          sortable: true,
          filterable: false,
          show: this.state.status === 'On Deck' || this.state.status === 'Scheduled',
          Cell: ({ row }) => (
            <Button simple color="white" className={classes.actionButton} onClick={() => this.handleClick(row._original)}>
              {row._original.released_at ? (
                <Moment format="MM/DD/YY h:mm a">{row._original.released_at}</Moment>
              ) : (
                <span>Schedule Ready</span>
              )}
            </Button>
          ),
          width: 160
        },
        {
          Header: "Order",
          accessor: "name",
          show: this.state.filterGroup === 'None',
          maxWidth: 80,
        },
        {
          Header: "Created",
          accessor: "created_at",
          filterable: false,
          show: false,
          Cell: ({ row }) => (
            <span>
              {row._original.created_at &&
                <Moment format="MM/DD/YY h:mm a">{row._original.created_at}</Moment>
              }
            </span>
          ),
          minWidth: 90
        },
        {
          Header: "Name",
          accessor: "customer",
          show: this.state.filterGroup === 'None',
        },
        {
          Header: "Email",
          accessor: "email",
          show: this.state.filterGroup === 'None',
        },
        {
          Header: "Org",
          accessor: "collection_name",
          show: this.state.filterGroup === 'None' || this.state.filterGroup === 'Product & Org',
        },
        {
          Header: "Orgs",
          accessor: "orgs",
          show: this.state.filterGroup === 'Product' || this.state.filterGroup === 'SKU',
          Cell: ({ row }) => (
            `${row.orgs.join(', ')}`
          ),
        },
        {
          Header: "Product",
          accessor: "title",
        },
        {
          Header: "Size",
          accessor: "size",
          maxWidth: 60,
          show: this.state.filterGroup === "None"
        },
        {
          Header: "Style",
          accessor: "style",
          show: this.state.filterGroup === "None"
        },
        {
          Header: "Color",
          accessor: "color",
          show: this.state.filterGroup === "None"
        },
        {
          Header: "SKU",
          accessor: "sku",
        },
        {
          Header: "Price",
          accessor: "price",
          filterable: false,
          Cell: ({ row }) => (
            `$${row.price}`
          ),
          maxWidth: 80,
          show: false
        },
        {
          Header: "Payment",
          accessor: "payment_type",
          show: false
        },
        {
          Header: "Delivery",
          accessor: "shipping_type",
        },
        {
          Header: "Notified",
          show: this.state.status === 'Ready',
          filterable: false,
          accessor: "released_at",
          Cell: ({ row }) => (
            <span>
              {row._original.released_at &&
                <Moment format="MM/DD/YY h:mm a">{row._original.released_at}</Moment>
              }
            </span>
          ),
          minWidth: 90
        },
        {
          Header: "Qty",
          headerStyle: {
            paddingRight: "10px",
            textAlign: "right",
          },
          accessor: "quantity",
          maxWidth: 60,
          className: classes.right,
          show: this.state.filterGroup !== "None",
          sortMethod: (a, b, desc) => {
            a = (a === 0) ? (desc ? -Infinity : Infinity) : a;
            b = (b === 0) ? (desc ? -Infinity : Infinity) : b;
            if (parseInt(a) > parseInt(b)) { return 1; }
            if (parseInt(a) < parseInt(b)) { return -1; }
            return 0;
          },
        },
        {
          Header: "Line Items",
          headerStyle: {
            paddingRight: "10px",
            textAlign: "right",
          },
          accessor: "lineItems.length",
          show: this.state.filterGroup !== 'None',
          className: classes.right,
          sortMethod: (a, b, desc) => {
            a = (a === 0) ? (desc ? -Infinity : Infinity) : a;
            b = (b === 0) ? (desc ? -Infinity : Infinity) : b;
            if (parseInt(a) > parseInt(b)) { return 1; }
            if (parseInt(a) < parseInt(b)) { return -1; }
            return 0;
          },
        },
      ]}
        loading={this.state.loading}
        showPaginationTop={false}
        showPaginationBottom={true}
        selectable={false}
        className="-striped"

        defaultSorted={this.state.sorted}
        defaultPage={this.state.page}
        defaultPageSize={this.state.pageSize}
        defaultExpanded={this.state.expanded}
        defaultResized={this.state.resized}
        defaultFiltered={this.state.filtered}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={4} sm={3} lg={2} className={classes.center}>
          <span className={classes.filterHeader}>Filter by</span>
          <CustomDropdown
            buttonText={this.state.status}
            buttonProps={{
              style: { marginBottom: "0", width: "120px" },
              color: "rose"
            }}
            dropdownList={statusTitlesFulfillment}
            onClick={(e) => this.handleStatusFilterChange(e)}
          />
        </GridItem>
        <GridItem xs={4} sm={3} lg={2} className={classes.center}>
          <span className={classes.filterHeader}>Group by</span>
          <CustomDropdown
            buttonText={this.state.filterGroup}
            buttonProps={{
              style: { marginBottom: "0", width: "120px" },
              color: "rose"
            }}
            dropdownList={aggregationMethods}
            onClick={(e) => this.handleGroupFilterChangeByClick(e)}
          />
        </GridItem>
        <GridItem xs={12} className={classes.marginTop}>
          { this.state.status === 'Pending' &&
            <h4 className={classes.center}>Orders have been marked <b>Purchasing Complete</b> and <b>Print Tags Complete</b>.</h4>
          }
          { this.state.status === 'On Deck' &&
            <h4 className={classes.center}>Orders are ready to be delivered or picked up. Release notifications must be scheduled.</h4>
          }
          { this.state.status === 'Scheduled' &&
            <h4 className={classes.center}>Orders are <b>On Deck</b> and have been scheduled.</h4>
          }
          { this.state.status === 'Ready' &&
            <h4 className={classes.center}>Customers have already been notified.</h4>
          }
        </GridItem>
        <GridItem xs={12}>
          {this.renderCard()}
        </GridItem>
        {this.renderModal()}
      </GridContainer>
    );
  }
}

FulfillmentTables.propTypes = {
  classes: PropTypes.object
};

export default withStyles(combineStyles(tableStyle, modalStyle))(withGracefulUnmount(FulfillmentTables));
